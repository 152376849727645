<template lang="pug">
  PageForm(:data="data")
  //- pre {{ data }}
</template>

<script>
import PageForm from './PageForm'

export default {
  name: 'PageAdd',
  components: {
    PageForm
  },
  data () {
    return {
      data: {
        id: undefined,
        title: '',
        content: '',
        thumbnail: undefined,
        gallery: undefined
      }
    }
  },
  computed: {},
  methods: {},
  created() {}
}
</script>

<style lang="less" scoped></style>